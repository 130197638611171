export const gmapMixin = {
  data() {
    return {
      coreMap: null,
      map: {
        center: { lat: 0, lng: 0 },
        zoom: 12,
        currentPlace: {}
      },
      geolocateCoords: {}
    };
  },
  created: function() {},
  methods: {
    updateMapOptions() {
      // get center and zoom level, store in localstorage
      let vm = this;
      vm.map.center = {
        lat: vm.coreMap.getCenter().lat(),
        lng: vm.coreMap.getCenter().lng()
      };
      vm.map.zoom = vm.coreMap.getZoom();
    },
    setPlace(place) {
      this.map.currentPlace = place;
    }
  }
};
