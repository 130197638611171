import { FETCH_ASSIGNOR_LOCATIONS } from "@/modules/assignorslocation/store/location.module";

export default {
  methods: {
    checkDuplicateCodes(code) {
      return new Promise((resolve) => {
        let errorMessage = "";
        this.$store
          .dispatch(FETCH_ASSIGNOR_LOCATIONS, "?code=" + code)
          .then((data) => {
            this.$nextTick(function() {
              let duplicates = data.data.items;
              if (duplicates && duplicates.length) {
                errorMessage +=
                  "<h2 class='text-center'>Намерени са локации с този код</h2>";
                errorMessage += `<hr />`;
                duplicates.forEach((d) => {
                  errorMessage += d.locationName + " | " + d.assignorName;
                  errorMessage += `<hr />`;
                });
                errorMessage +=
                  "<b>Сигурни ли сте, че искате да запазите обекта с този код?</b>";
                this.$dialog
                  .confirm(errorMessage, {
                    html: true,
                    customClass: "confirmation-modal",
                  })
                  .then(() => {
                    resolve();
                  });
              } else {
                resolve();
              }
            });
          });
      });
    },
  },
};
